import Navigation from "./components/nav/navigation";
import Footer from "../src/components/footer/footer";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Routing from "./routes/routes";

const App = () => {
  return (
    <div className="wrapper">
      <BrowserRouter>
        <Navigation />
        <Routing />
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;

import React from "react";
import { Carousel } from "react-bootstrap";

const Carousels = ({ img1, img2, img3, img4, img5, img6 }) => {
  return (
    <>
      <Carousel fade className="sliderCarousel">
        <Carousel.Item>
          <img src={img1} alt="..." className="sliderImg" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={img2} alt="..." className="sliderImg" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={img3} alt="..." className="sliderImg" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={img4} alt="..." className="sliderImg" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={img5} alt="..." className="sliderImg" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={img6} alt="..." className="sliderImg" />
        </Carousel.Item>
      </Carousel>
    </>
  );
};

export default Carousels;

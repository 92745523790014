import { useParams } from "react-router-dom";
import React, { useState } from "react";
import Select from "react-select";

const Contact = () => {
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [address, setAddress] = useState("");
  const [body, setBody] = useState("");
  const { subject } = useParams();
  const [subj, setSubject] = useState(subject);

  const handleName = (val) => {
    setName(val);
  };
  const handleSurname = (val) => {
    setSurname(val);
  };
  const handlePhone = (val) => {
    setPhone(val);
  };
  const handleAddress = (val) => {
    setAddress(val);
  };
  const handleBody = (val) => {
    setBody(val);
  };
  const handleSubject = (val) => {
    setSubject(val.value);
  };

  const getDefault = () => {
    for (let index = 0; index < options.length; index++) {
      if (subject === options[index].value) {
        return options[index];
      }
    }
  };

  const buildEmail = () => {
    let to = "keananeksteen7@gmail.com";
    let su = "?subject=" + subj;
    let ctc = "Please contact me via " + phone;
    let nme = "Kind regards, %0D%0A" + name + " " + surname;
    let adrs = "My location : " + address;
    let bodies = "&body=" + body.replace(new RegExp("\r?\n", "g"), "%0D%0A") + "%0D%0A%0D%0A" + adrs + "%0D%0A%0D%0A" + ctc + "%0D%0A%0D%0A" + nme;
    let combined = "mailto:" + to + su + bodies;
    const win = window.open(combined, "_blank");
    win.focus();
  };

  const options = [
    { value: "Glass & Mirrors", label: "Glass & Mirrors" },
    { value: "Aluminium Doors & Windows", label: "Aluminium Doors & Windows" },
    { value: "Window Tinting", label: "Window Tinting" },
    { value: "Shower Doors", label: "Shower Doors" },
    { value: "Maintenance", label: "Maintenance" },
    { value: "Burglar Bars", label: "Burglar Bars" },
    { value: "Other Services", label: "Other Services" },
  ];

  return (
    <div className="contactPage">
      <div className="contactHeading"> - CONTACT US -</div>
      <div class="row" id="contactRow">
        <div class="col" id="contactCard">
          <div class="card" id="contactsCardLeft">
            <h5 class="card-header">Contact Details</h5>
            <div class="card-body">
              <img src={"/images/telephone.svg"} alt="..." className="contactCardIcons"></img>
              <h5 class="card-title">Phone Us</h5>
              <p class="card-text">
                Cell : <a href="tel:0799632510">079 963 2510</a>
              </p>
              <h5 className="contactEmailHeading">Email Us</h5>
              <p className="contactLinks">
                <a className="emailHref" href="mailto:keananeksteen7@gmail.com">
                  keananeksteen7@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div class="col" id="contactCard">
          <div class="card" id="contactsCardRight">
            <h5 class="card-header">Trading Hours</h5>
            <div class="card-body">
              <img src={"/images/clock.svg"} alt="..." className="contactCardIcons"></img>
              <div className="paragraphContainer">
                <p class="card-text">
                  <b className="dayLeft">Monday - Friday :</b> 08:00 - 17:00
                </p>
                <p class="card-text">
                  <b className="dayLeft">Saturday : </b>08:00 - 13:00
                </p>
                <p class="card-text">
                  <b className="dayLeft">Sunday : </b>Closed
                </p>
                <p class="card-text">
                  <b className="dayLeft">Public Holidays : </b>Closed
                </p>
                <p class="card-text">
                  <b className="dayLeft">Enquiries : </b> 24h - 7 days a week
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" id="contactRowBottom">
        <div class="container contact-form">
          <form onSubmit={() => buildEmail()}>
            <h3 id="contactFormTitle"> - DROP US A MESSAGE - </h3>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <input type="text" name="txtName" required class="form-control" placeholder="First Name" onChange={(e) => handleName(e.target.value)} />
                </div>
                <div class="form-group">
                  <input type="text" name="txtSurname" required class="form-control" placeholder="Last Name" onChange={(e) => handleSurname(e.target.value)} />
                </div>
                <div class="form-group">
                  <Select defaultValue={getDefault()} options={options} onChange={handleSubject} placeholder={"Select Products"} />
                </div>
                <div class="form-group">
                  <input name="txtPhone" type="number" pattern="[789][0-9]{9}" required class="form-control" placeholder="Phone Number" onChange={(e) => handlePhone(e.target.value)} />
                </div>
                <div class="form-group">
                  <input type="text" name="txtAddress" class="form-control" required placeholder="Physical Address" onChange={(e) => handleAddress(e.target.value)} />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group" id="btnSubmitContainer">
                  <textarea name="txtMsg" class="form-control" required placeholder="Message" id="textAreaContact" onChange={(e) => handleBody(e.target.value)}></textarea>
                </div>
                <div class="form-group">
                  <input type="submit" value="Confirm Message" className="btnSubmit" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;

import React from "react";
import "react-slideshow-image/dist/styles.css";
import Carousels from "../tools/carousel";

const Slideshow = () => {
  return (
    <>
      <div className="sliderContainer">
        <div className="sliderPrimary">
          <div className="paragraphSliderContainer">
            <div className="sliderTitle">Completed Door Installations</div>
          </div>
          <div className="carouselContainer">
            <Carousels
              img1={"/images/doors/d1.webp"}
              img2={"/images/doors/d2.webp"}
              img3={"/images/doors/d3.webp"}
              img4={"/images/doors/d4.webp"}
              img5={"/images/doors/d5.webp"}
              img6={"/images/doors/d6.webp"}
            />
          </div>
        </div>
        <div className="sliderSecondary">
          <div className="paragraphSliderContainer">
            <div className="sliderTitle">Completed Window Installations</div>
          </div>
          <div className="carouselContainer">
            <Carousels
              img1={"/images/other/o5.webp"}
              img2={"/images/windows/w2.webp"}
              img3={"/images/windows/w3.webp"}
              img4={"/images/windows/w4.webp"}
              img5={"/images/windows/w5.webp"}
              img6={"/images/windows/w1.webp"}
            />
          </div>
        </div>
        <div className="sliderPrimary">
          <div className="paragraphSliderContainer">
            <div className="sliderTitle">Other Services</div>
          </div>
          <div className="carouselContainer">
            <Carousels
              img1={"/images/other/o4.webp"}
              img2={"/images/other/o2.webp"}
              img3={"/images/other/o3.webp"}
              img4={"/images/other/o1.webp"}
              img5={"/images/vinyl1.webp"}
              img6={"/images/other/o6.webp"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Slideshow;

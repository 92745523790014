import { Navbar } from "react-bootstrap";
import { useMediaQuery } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { useState } from "react";
import Hamburger from "./navigationMobile";

const Navigation = () => {
  const responsiveInfo = useMediaQuery("(max-width:750px)");
  const responsive = useMediaQuery("(max-width:500px)");
  const [showMobile, setShowMobile] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const handleMobileNavbar = (val) => {
    if (val) {
      document.getElementById("sidebar")?.classList.toggle("active");
      document.getElementById("toggleButton")?.classList.toggle("active");
    } else {
      document.getElementById("sidebar")?.classList.toggle("inactive");
      document.getElementById("toggleButton")?.classList.toggle("inactive");
    }
  };

  return (
    <>
      {responsive === false ? (
        <Navbar className="navigation">
          <div className="navFirstSection">
            <div className="navigationImage">
              <img src={"/images/icon.webp"} alt="..." className="navigationLogo" onClick={() => history.push("/")} />
            </div>
            <div className="navigationTitleGroup" hidden={responsiveInfo ? true : false}>
              <img src={"/images/name.webp"} alt="..." className="navigationTitle" />
              <img src={"/images/title.webp"} alt="..." className="navigationSub" />
            </div>
          </div>
          <div className="navigationLinks">
            <div className={location.pathname === "/" ? "pageLinkActive" : "pageLink"} onClick={() => history.push("/")}>
              HOME
            </div>
            <div className={location.pathname.includes("/services") ? "pageLinkActive" : "pageLink"} onClick={() => history.push("/services")}>
              SERVICES
            </div>
            <div className={location.pathname.includes("/contact") ? "pageLinkActive" : "pageLink"} onClick={() => history.push("/contact")}>
              CONTACT
            </div>
            <div className={location.pathname.includes("/about") ? "pageLinkActive" : "pageLink"} onClick={() => history.push("/about")}>
              ABOUT
            </div>
          </div>
        </Navbar>
      ) : (
        <Navbar className="navigation">
          <div className="navFirstSection">
            <div className="navigationImage">
              <img src={"/images/icon.webp"} alt="..." className="navigationLogo" onClick={() => history.push("/")} />
            </div>
          </div>
          <div className="mobileNavigationColIcon">
            <img src={"/images/list.svg"} style={{ width: "50%", float: "right" }} alt="..." id="toggleButton" onClick={() => handleMobileNavbar(!showMobile)} />
          </div>
        </Navbar>
      )}
      <Hamburger />
    </>
  );
};

export default Navigation;

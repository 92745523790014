import React from "react";

const About = () => {
  return (
    <>
      <div className="topSection">
        <img className="aboutProfile" src={"/images/profile.webp"} alt="..."></img>
      </div>
      <div className="aboutDescription">
        <p className="aboutDescriptionTitle">WHAT WE DO?</p>
        <p className="aboutDescriptionParagraph">
          We specialize in the supply and installation of a range of aluminium products that include, but are not limited to aluminium doors, windows, showers, burglar bars, tinting and vinyl etc..
        </p>

        <p className="aboutDescriptionTitle">HOW WE OPERATE?</p>
        <p className="aboutDescriptionParagraph">
          After communication has been established, an appointment will be scheduled for quotation purposes. Thereafter a quotation will be sent to the client for approval of service. A deposit will
          be required for all aluminium products, which will be stated on the quotation. All glass and mirrors are to be paid for in full on the confirmation of order.
        </p>

        <p className="aboutDescriptionTitle">WHERE WE OPERATE?</p>
        <p className="aboutDescriptionParagraph">
          We operate mainly in Cape Town and surrounding areas including Stellenbosch, Paarl, Wellington, etc.. Any orders outside this area will be considered, but additional fees may be charged.
        </p>
      </div>
      <div className="mapsSection">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d423811.72306371015!2d18.375880142197445!3d-33.914650964788166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc500f8826eed7%3A0x687fe1fc2828aa87!2sCape%20Town!5e0!3m2!1sen!2sza!4v1624914722527!5m2!1sen!2sza"
          className="aboutMap"
          allowfullscreen=""
          loading="lazy"
          title="google-map"
        ></iframe>
      </div>
    </>
  );
};

export default About;

import React from "react";

const Footer = () => {
  return (
    <div class="footerBasic">
      <footer>
        <div class="social">
          <a href="mailto:keananeksteen7@gmail.com" target="_blank">
            <i class="icon ion-social-google"></i>
          </a>
          <a href="https://www.facebook.com/Keanan-Eksteen-Glass-Aluminium-100732531343923" target="_blank">
            <i class="icon ion-social-facebook"></i>
          </a>
          <a href="tel:0799632510" target="_blank">
            <img style={{ paddingBottom: "5px" }} src="/images/phone.svg"></img>
          </a>
        </div>
        <ul class="listLine">
          <li class="list-inline-item">
            <a href="/about">About</a>
          </li>
          <li class="list-inline-item">
            <img src={"/images/dash-lg.svg"} alt="..." />
          </li>
          <li class="list-inline-item">
            <a href="/services">Services</a>
          </li>
          <li class="list-inline-item">
            <img src={"/images/dash-lg.svg"} alt="..." />
          </li>
          <li class="list-inline-item">
            <a href="/contact">Contact</a>
          </li>
        </ul>
        <p class="copyright">KEANAN EKSTEEN GLASS & ALUMINIUM © 2017</p>
      </footer>
    </div>
  );
};

export default Footer;

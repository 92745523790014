import React from "react";

const Heading = () => {
  return (
    <div className="mainHeading">
      <h1 className="headingPrimary">KEANAN EKSTEEN</h1>
      <h2 className="headingSecondary">GLASS & ALUMINIUM</h2>
      <p className="headingParagraph">
        We specialize in the installation of aluminium windows and doors, as well as other services that include: showers, burglar bars, window tinting and maintenance on aluminium products. Feel free
        to browse our website to see what we have to offer. Please don't hesitate to contact us for any queries regarding our services.
      </p>
      <div className="headingRequest">
        <button
          onClick={() => {
            const win = window.open("/contact/General Quotation?#contactRowBottom", "_self");
            win.focus();
          }}
          className="btnPrimary"
        >
          REQUEST A QUOTE
        </button>
        <button
          onClick={() => {
            const win = window.open("tel:0799632510", "_blank");
            win.focus();
          }}
          className="btnSecondary"
        >
          CALL US
        </button>
      </div>
    </div>
  );
};

export default Heading;

import { useEffect, useState } from "react";
import { Card, Container, Button } from "react-bootstrap";
import { useMediaQuery } from "@material-ui/core";

const Content = () => {
  const med = useMediaQuery("(max-width: 1150px)");
  const sml = useMediaQuery("(max-width: 700px)");
  const [cardClass, setCardClass] = useState("");

  useEffect(() => {
    if (sml === true) {
      setCardClass("row row-cols-1 row-cols-md-1");
    } else {
      if (med === true) {
        setCardClass("row row-cols-1 row-cols-md-2");
      } else {
        setCardClass("row row-cols-1 row-cols-md-3");
      }
    }
  }, [med, sml]);

  return (
    <div>
      <Container className="contentContainer">
        <div class={cardClass && cardClass} id="cardGrid">
          <div className="cardCol">
            <a id="serviceTags" href="/services/cardGlass/#cardGlass">
              <Card className="homeCards">
                <Card.Body>
                  <div className="contentContainerBody">
                    <img src={"/images/cutIcon.webp"} class="cardImgTop" alt="..." />
                    <Card.Title className={"homeCards_title"}>Glass & Mirrors</Card.Title>
                    <Card.Text className={"homeCards_text"}>Glass and mirrors can be ordered and cut to size.</Card.Text>
                  </div>
                  <Button id="servicesSpacedButton" variant="primary">
                    View service
                  </Button>
                </Card.Body>
              </Card>
            </a>
          </div>
          <div className="cardCol">
            <a id="serviceTags" href="/services/cardDoors/#cardDoors">
              <Card className="homeCards">
                <Card.Body>
                  <div className="contentContainerBody">
                    <img src={"/images/dooricon.webp"} class="cardImgTop" alt="..." />
                    <Card.Title className={"homeCards_title"}>Aluminium Doors & Windows</Card.Title>
                    <Card.Text className={"homeCards_text"}>We specialize in the conversion of steel and hardwood frames to aluminium frames.</Card.Text>
                  </div>
                  <Button id="servicesSpacedButton" variant="primary">
                    View service
                  </Button>
                </Card.Body>
              </Card>
            </a>
          </div>
          <div className="cardCol">
            <a id="serviceTags" href="/services/cardWindows/#cardWindows">
              <Card className="homeCards">
                <Card.Body>
                  <div className="contentContainerBody">
                    <img src={"/images/windowIcon.webp"} class="cardImgTop" alt="..." />
                    <Card.Title className={"homeCards_title"}>Window Tinting</Card.Title>
                    <Card.Text className={"homeCards_text"}>We perform window tinting and vinyl patterns on windows and doors.</Card.Text>
                  </div>
                  <Button id="servicesSpacedButton" variant="primary">
                    View service
                  </Button>
                </Card.Body>
              </Card>
            </a>
          </div>
          <div className="cardCol">
            <a id="serviceTags" href="/services/cardShower/#cardShower">
              <Card className="homeCards">
                <Card.Body>
                  <div className="contentContainerBody">
                    <img src={"/images/showerIcon.webp"} class="cardImgTop" alt="..." />
                    <Card.Title className={"homeCards_title"}>Shower Doors</Card.Title>
                    <Card.Text className={"homeCards_text"}>We supply and fit a variety of shower doors.</Card.Text>
                  </div>
                  <Button id="servicesSpacedButton" variant="primary">
                    View service
                  </Button>
                </Card.Body>
              </Card>
            </a>
          </div>
          <div className="cardCol">
            <a id="serviceTags" href="/services/cardMaintenence/#cardMaintenence">
              <Card className="homeCards">
                <Card.Body>
                  <div className="contentContainerBody">
                    <img src={"/images/maintainIcon.webp"} class="cardImgTop" alt="..." />
                    <Card.Title className={"homeCards_title"}>Maintenance</Card.Title>
                    <Card.Text className={"homeCards_text"}>We specialize in general maintenance of aluminium products and re-puttying.</Card.Text>
                  </div>
                  <Button id="servicesSpacedButton" variant="primary">
                    View service
                  </Button>
                </Card.Body>
              </Card>
            </a>
          </div>
          <div className="cardCol">
            <a id="serviceTags" href="/services/cardInstallations/#cardInstallations">
              <Card className="homeCards">
                <Card.Body>
                  <div className="contentContainerBody">
                    <img src={"/images/installIcon.webp"} class="cardImgTop" alt="..." />
                    <Card.Title className={"homeCards_title"}>Burglar Bars</Card.Title>
                    <Card.Text className={"homeCards_text"}>We make use of tried and tested methods and equipment when installing burglar bars.</Card.Text>
                  </div>
                  <Button id="servicesSpacedButton" variant="primary">
                    View service
                  </Button>
                </Card.Body>
              </Card>
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Content;

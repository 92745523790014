import React from "react";
import Content from "../components/content/content";
import Heading from "../components/heading/heading";
import Slider from "../components/slider/slider";

const Home = () => {
  return (
    <>
      <Heading />
      <div class="contentDiv">
        <img class="arrow" src="/images/chevron-double-down.svg" />
      </div>
      <Content />
      <Slider />
    </>
  );
};

export default Home;

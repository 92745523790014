import { useHistory } from "react-router";

const NavigationHamburger = () => {
  const location = useHistory();

  const closeSidebar = () => {
    document.getElementById("sidebar")?.classList.toggle("active");
    document.getElementById("toggleButton")?.classList.toggle("active");
  };
  return (
    <>
      <div id="sidebar">
        <div id="sidebarGroup">
          <div id="sidebarGroupIcon">
            <img src={"/images/chevron-right.svg"} alt="..." className="mobileNavigationCloseIcon" onClick={() => closeSidebar()} />
          </div>
        </div>
        <ul className="uiGroup">
          <li
            onClick={() => {
              location.push("/");
              closeSidebar();
            }}
            className="liGroup"
          >
            <img src={"/images/house-fill.svg"} className="mobileNavigationListIcons" alt="..." />
            HOME
          </li>
          <li
            onClick={() => {
              location.push("/services");
              closeSidebar();
            }}
            className="liGroup"
          >
            <img src={"/images/gear.svg"} className="mobileNavigationListIcons" alt="..." />
            SERVICES
          </li>
          <a href="/services/cardDoors?#cardDoors" id="mobileTags" onClick={() => closeSidebar()}>
            <li className="liSubGroup">
              <img src={"/images/caret-right-fill.svg"} className="mobileNavigationListIcons" alt="..." />
              DOORS & WINDOWS
            </li>
          </a>
          <a href="/services/cardGlass?#cardGlass" id="mobileTags" onClick={() => closeSidebar()}>
            <li className="liSubGroup">
              <img src={"/images/caret-right-fill.svg"} className="mobileNavigationListIcons" alt="..." />
              GLASS & MIRROR CUT
            </li>
          </a>

          <a href="/services/cardWindows?#cardWindows" id="mobileTags" onClick={() => closeSidebar()}>
            <li className="liSubGroup">
              <img src={"/images/caret-right-fill.svg"} className="mobileNavigationListIcons" alt="..." />
              WINDOW TINTING & VINYL
            </li>
          </a>
          <a href="/services/cardShower?#cardShower" id="mobileTags" onClick={() => closeSidebar()}>
            <li className="liSubGroup">
              <img src={"/images/caret-right-fill.svg"} className="mobileNavigationListIcons" alt="..." />
              SHOWER DOORS
            </li>
          </a>
          <a href="/services/cardMaintenence?#cardMaintenence" id="mobileTags" onClick={() => closeSidebar()}>
            <li className="liSubGroup">
              <img src={"/images/caret-right-fill.svg"} className="mobileNavigationListIcons" alt="..." />
              MAINTENANCE
            </li>
          </a>
          <a href="/services/cardInstallations?#cardInstallations" id="mobileTags" onClick={() => closeSidebar()}>
            <li className="liSubGroup">
              <img src={"/images/caret-right-fill.svg"} className="mobileNavigationListIcons" alt="..." />
              BURGLAR BARS
            </li>
          </a>

          <li
            className="liGroup"
            onClick={() => {
              location.push("/contact");
              closeSidebar();
            }}
          >
            <img src={"/images/telephone-white.svg"} className="mobileNavigationListIcons" alt="..." />
            CONTACT US
          </li>
          <li
            onClick={() => {
              location.push("/about");
              closeSidebar();
            }}
            className="liGroup"
          >
            <img src={"/images/info-circle.svg"} className="mobileNavigationListIcons" alt="..." />
            ABOUT
          </li>
        </ul>
      </div>
    </>
  );
};

export default NavigationHamburger;

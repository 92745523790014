import React, { useState, useEffect } from "react";
import { Collapse } from "react-bootstrap";
import { useParams } from "react-router-dom";

const Service = () => {
  const [glassOpen, setGlassOpen] = useState(false);
  const [doorOpen, setDoorOpen] = useState(false);
  const [windowOpen, setWindowOpen] = useState(false);
  const [showerOpen, setShowerOpen] = useState(false);
  const [renovationsOpen, setRenovationsOpen] = useState(false);
  const [burglarOpen, setBurglarOpen] = useState(false);
  const { division } = useParams();

  useEffect(() => {
    switch (division) {
      case "cardGlass":
        setGlassOpen(true);
        break;
      case "cardDoors":
        setDoorOpen(true);
        break;
      case "cardWindows":
        setWindowOpen(true);
        break;
      case "cardShower":
        setShowerOpen(true);
        break;
      case "cardMaintenence":
        setRenovationsOpen(true);
        break;
      case "cardInstallations":
        setBurglarOpen(true);
        break;

      default:
        break;
    }
  }, [window.location.pathname]);

  return (
    <div className="servicesPage">
      <div className="servicesTitle"> - OUR SERVICES - </div>
      <div class="card" id="servicesCard">
        <div class="card-header" id="cardDoors" onClick={() => setDoorOpen(!doorOpen)} aria-controls="example-collapse-text">
          {doorOpen ? <img className="servicesHeaderIconLeft" src={"/images/dash-circle.svg"} alt="..." /> : <img className="servicesHeaderIconLeft" src={"/images/plus-circle.svg"} alt="..." />}
          ALUMINIUM DOORS & WINDOWS
          {doorOpen ? <img className="servicesHeaderIconRight" src={"/images/dash-circle.svg"} alt="..." /> : <img className="servicesHeaderIconRight" src={"/images/plus-circle.svg"} alt="..." />}
        </div>
        <Collapse in={doorOpen}>
          <div class="card-body" aria-expanded={doorOpen}>
            <p class="card-text" id="servicesParagraph">
              There are a variety of colors available in the aluminium products we use for our services. The windows are typically made to a conventional layout, but we can customize the arrangement
              and dimensions to meet your needs. We specialize in the conversion of steel and hardwood frames to aluminium frames, as well as glass and aluminium product repairs and maintenance.
            </p>
            <div className="serviceImagesRow">
              <div className="serviceImagesCol" id="largeOnly">
                <img alt="..." src={"/images/doors/d2.webp"} className="serviceImages" id="serviceImagesLeft" />
              </div>
              <div className="serviceImagesCol">
                <img alt="..." src={"/images/windows/w2.webp"} className="serviceImages" />
              </div>
              <div className="serviceImagesCol" id="largeOnly">
                <img alt="..." src={"/images/doors/d6.webp"} className="serviceImages" id="serviceImagesRight" />
              </div>
            </div>
            <div className="servicesButtonContainer">
              <a href="/contact/Aluminium Doors & Windows?#contactRowBottom" class="btn btn-primary" id="servicesButton">
                Service Quote
              </a>
            </div>
          </div>
        </Collapse>
      </div>
      <div class="card" id="servicesCard">
        <div className="card-header" id="cardGlass" onClick={() => setGlassOpen(!glassOpen)} aria-controls="example-collapse-text">
          {glassOpen ? <img alt="..." className="servicesHeaderIconLeft" src={"/images/dash-circle.svg"} /> : <img alt="..." className="servicesHeaderIconLeft" src={"/images/plus-circle.svg"} />}
          GLASS & MIRROR CUT
          {glassOpen ? <img alt="..." className="servicesHeaderIconRight" src={"/images/dash-circle.svg"} /> : <img alt="..." className="servicesHeaderIconRight" src={"/images/plus-circle.svg"} />}
        </div>
        <Collapse in={glassOpen}>
          <div class="card-body" aria-expanded={glassOpen}>
            <p class="card-text" id="servicesParagraph">
              We provide clear float glass and mirrors, as well as bronze or grey tinted float glass and mirrors. Laminated and toughened safety glass are also available, alongside acoustic and double
              glazing glass. We offer polishing, beveling and drilling of holes for mirror installations.
            </p>
            <div className="serviceImagesRow">
              <div className="serviceImagesCol" id="largeOnly">
                <img alt="..." src={"/images/reputty1.webp"} className="serviceImages" id="serviceImagesLeft" />
              </div>
              <div className="serviceImagesCol">
                <img alt="..." src={"/images/reputty2.webp"} className="serviceImages" />
              </div>
              <div className="serviceImagesCol" id="largeOnly">
                <img alt="..." src={"/images/reputty3.webp"} className="serviceImages" id="serviceImagesRight" />
              </div>
            </div>
            <div className="servicesButtonContainer">
              <a href="/contact/Glass & Mirrors?#contactRowBottom" class="btn btn-primary" id="servicesButton">
                Service Quote
              </a>
            </div>
          </div>
        </Collapse>
      </div>

      <div class="card" id="servicesCard">
        <div class="card-header" id="cardWindows" onClick={() => setWindowOpen(!windowOpen)}>
          {windowOpen ? <img alt="..." className="servicesHeaderIconLeft" src={"/images/dash-circle.svg"} /> : <img className="servicesHeaderIconLeft" src={"/images/plus-circle.svg"} alt="..." />}
          WINDOW TINTING & VINYL
          {windowOpen ? <img alt="..." className="servicesHeaderIconRight" src={"/images/dash-circle.svg"} /> : <img className="servicesHeaderIconRight" src={"/images/plus-circle.svg"} alt="..." />}
        </div>
        <Collapse in={windowOpen}>
          <div class="card-body" aria-expanded={windowOpen}>
            <p class="card-text" id="servicesParagraph">
              We have the ability to tint or add vinyl shading to any glass or window surface. The materials we use for this service are of high quality.
            </p>
            <div className="serviceImagesRow">
              <div className="serviceImagesCol" id="largeOnly">
                <img alt="..." src={"/images/vinyl1.webp"} className="serviceImages" id="serviceImagesLeft" />
              </div>
              <div className="serviceImagesCol">
                <img alt="..." src={"/images/vinyl3.webp"} className="serviceImages" />
              </div>
              <div className="serviceImagesCol" id="largeOnly">
                <img alt="..." src={"/images/vin2.webp"} className="serviceImages" id="serviceImagesRight" />
              </div>
            </div>
            <div className="servicesButtonContainer">
              <a href="/contact/Window Tinting?#contactRowBottom" class="btn btn-primary" id="servicesButton">
                Service Quote
              </a>
            </div>
          </div>
        </Collapse>
      </div>
      <div class="card" id="servicesCard">
        <div class="card-header" id="cardShower" onClick={() => setShowerOpen(!showerOpen)}>
          {showerOpen ? <img className="servicesHeaderIconLeft" src={"/images/dash-circle.svg"} alt="..." /> : <img className="servicesHeaderIconLeft" src={"/images/plus-circle.svg"} alt="..." />}
          SHOWER DOORS
          {showerOpen ? <img className="servicesHeaderIconRight" src={"/images/dash-circle.svg"} alt="..." /> : <img className="servicesHeaderIconRight" src={"/images/plus-circle.svg"} alt="..." />}
        </div>
        <Collapse in={showerOpen}>
          <div class="card-body" aria-expanded={showerOpen}>
            <p class="card-text" id="servicesParagraph">
              We provide showers with a maintenance-free aluminium frame and safety glass. We also do single pivots, double pivots, bifold, pentagonal, tri-glide, corner-entry and inline doors. The
              showers we install could either be quarter-rounded, framed or frameless. Shower enclosures can also be customized to meet your specific requirements.
            </p>
            <div className="serviceImagesRow">
              <div className="serviceImagesCol" id="largeOnly">
                <img alt="..." src={"/images/other/o3.webp"} className="serviceImages" id="serviceImagesLeft" />
              </div>
              <div className="serviceImagesCol">
                <img alt="..." src={"/images/show2.webp"} className="serviceImages" />
              </div>
              <div className="serviceImagesCol" id="largeOnly">
                <img alt="..." src={"/images/shower1.webp"} className="serviceImages" id="serviceImagesRight" />
              </div>
            </div>
            <div className="servicesButtonContainer">
              <a href="/contact/Shower Doors?#contactRowBottom" class="btn btn-primary" id="servicesButton">
                Service Quote
              </a>
            </div>
          </div>
        </Collapse>
      </div>
      <div class="card" id="servicesCard">
        <div class="card-header" id="cardMaintenence" onClick={() => setRenovationsOpen(!renovationsOpen)}>
          {renovationsOpen ? (
            <img className="servicesHeaderIconLeft" src={"/images/dash-circle.svg"} alt="..." />
          ) : (
            <img className="servicesHeaderIconLeft" src={"/images/plus-circle.svg"} alt="..." />
          )}
          MAINTENANCE
          {renovationsOpen ? (
            <img className="servicesHeaderIconRight" src={"/images/dash-circle.svg"} alt="..." />
          ) : (
            <img className="servicesHeaderIconRight" src={"/images/plus-circle.svg"} alt="..." />
          )}
        </div>
        <Collapse in={renovationsOpen}>
          <div class="card-body" aria-expanded={renovationsOpen}>
            <p class="card-text" id="servicesParagraph">
              We do maintenance on all existing aluminium doors, windows, balustrades as well as showers. We also believe that we are specialists at giving the best advice and possible solutions to
              suit our client’s needs.
            </p>
            <div className="serviceImagesRow">
              <div className="serviceImagesCol" id="largeOnly">
                <img alt="..." src={"/images/mnt1.webp"} className="serviceImages" id="serviceImagesLeft" />
              </div>
              <div className="serviceImagesCol">
                <img alt="..." src={"/images/mtn2.webp"} className="serviceImages" />
              </div>
              <div className="serviceImagesCol" id="largeOnly">
                <img alt="..." src={"/images/mtn3.webp"} className="serviceImages" id="serviceImagesRight" />
              </div>
            </div>
            <div className="servicesButtonContainer">
              <a href="/contact/Maintenance?#contactRowBottom" class="btn btn-primary" id="servicesButton">
                Service Quote
              </a>
            </div>
          </div>
        </Collapse>
      </div>
      <div class="card" id="servicesCard">
        <div class="card-header" id="cardInstallations" onClick={() => setBurglarOpen(!burglarOpen)}>
          {burglarOpen ? <img className="servicesHeaderIconLeft" src={"/images/dash-circle.svg"} alt="..." /> : <img className="servicesHeaderIconLeft" src={"/images/plus-circle.svg"} alt="..." />}
          BURGLAR BARS
          {burglarOpen ? <img className="servicesHeaderIconRight" src={"/images/dash-circle.svg"} alt="..." /> : <img className="servicesHeaderIconRight" src={"/images/plus-circle.svg"} alt="..." />}
        </div>
        <Collapse in={burglarOpen}>
          <div class="card-body" aria-expanded={burglarOpen}>
            <p class="card-text" id="servicesParagraph">
              We provide high quality burglar bars for all window sizes and frames.
            </p>
            <div className="serviceImagesRow">
              <div className="serviceImagesCol" id="largeOnly">
                <img alt="..." src={"/images/other/o1.webp"} className="serviceImages" id="serviceImagesLeft" />
              </div>
              <div className="serviceImagesCol">
                <img alt="..." src={"/images/burglarbar2.webp"} className="serviceImages" />
              </div>
              <div className="serviceImagesCol" id="largeOnly">
                <img alt="..." src={"/images/burglarbar3.webp"} className="serviceImages" id="serviceImagesRight" />
              </div>
            </div>
            <div className="servicesButtonContainer">
              <a href="/contact/Burglar Bars?#contactRowBottom" class="btn btn-primary" id="servicesButton">
                Service Quote
              </a>
            </div>
          </div>
        </Collapse>
      </div>
      <div className="reviewGrid">
        <div class="card" id="servicesCardLeft">
          <div class="card-body">
            <h5 class="card-title">OUR RATING</h5>
            <img alt="..." src={"/images/stars.webp"} className="reviewStars" />
          </div>
        </div>
        <div class="card" id="servicesCardRight">
          <div class="card-body">
            <h5 class="card-title">REVIEW US</h5>
            <a href="https://www.facebook.com/Keanan-Eksteen-Glass-Aluminium-100732531343923/reviews/?ref=page_internal" target="_blank">
              <img alt="..." src={"/images/fbreview.webp"} className="btnFacebookReview"></img>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;

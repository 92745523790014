import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "../pages/home";
import Service from "../pages/service";
import About from "../pages/about";
import Contact from "../pages/contact";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/services/:division?">
        <Service />
      </Route>
      <Route exact path="/about">
        <About />
      </Route>
      <Route exact path="/contact/:subject?">
        <Contact />
      </Route>
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default Routes;
